import React, { useContext } from 'react';

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import router from 'next/router';
import Avatar from '@atoms/Avatar';
import Button from '@atoms/Button.new';

import {
  Dropdown,
  DropdownMenu,
  DropdownTrigger,
  DropdownItem
} from '@atoms/new/dropdown';

import { TeamsDataContext } from '@components/context/TeamsContext';
import { MicrosoftTeamsDataContext } from '@teams-app/components/contexts/MicrosoftTeamsContext';
import ExternalLink from '@teams-app/atoms/ExternalLink';

const DropdownDestination = ({ logout, nextParam }) => {
  const {
    currentTeam,
    user
  } = useContext(TeamsDataContext);

  const { isLoggedIn } = useContext(MicrosoftTeamsDataContext);

  // if (!currentTeam) {
  //   return <></>;
  // }

  if (!isLoggedIn()) {
    return <></>;
  }

  return (
    <div id="DropdownDestination" className="">
      <div className='flex'>
        <Dropdown
          placement="top-end"
          triggerScaleOnOpen={false}
          disableAnimation
        >
          <DropdownTrigger>
            <div>
              <Avatar
                w='8'
                h='8'
                name={user?.email || 'Guest'}
                isProfile={true}
              />
            </div>
          </DropdownTrigger>
          <DropdownMenu
            itemClasses={{
              base: 'hover:bg-red-500',
            }}
            onAction={(id) => {}}
            aria-label="Dropdown menu for list of presentations"
            selectionMode="none"
            topContent={
              <div
                className="flex items-center justify-center w-full py-2 px-4"
              >
                {user.user_role === 'guest' ? 'Guest Mode' : user.email}
              </div>
            }
          >

            <DropdownItem
              key="Logout"
              textValue="Logout"
              onAction={() => logout()}
              startContent={<LogoutRoundedIcon />}
            >
                  Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

      </div >
    </div >
  );
};

export default DropdownDestination;
