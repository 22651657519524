import { useContext } from 'react';
import { useTheme } from 'next-themes';
import { TeamsDataContext } from '@components/context/TeamsContext';
import { MicrosoftTeamsDataContext } from '@teams-app/components/contexts/MicrosoftTeamsContext';

import useRedirect from '@teams-app/hooks/useRedirect';

import Container from '@atoms/Container';

import DropdownDestination from './MicrosoftTeamsMenu';
import router from 'next/router';

const Header = (props) => {
  const { theme } = useTheme();

  const { doLogout, user } = useContext(TeamsDataContext);
  const { initGuest, isLoggedIn } = useContext(MicrosoftTeamsDataContext);

  const { redirectToHome, getNextParam } = useRedirect();

  const logout = () => {
    doLogout();
    initGuest();
    // redirectToHome();
    router.push('/teams-app/login');
  };

  return (
    <div className={`bg-grey-50 w-full z-50 border-b border-grey-200 ${!props.shape && ''}`}>
      <Container type='teams' className={'!px-4 py-4 mx-auto'}>
        <div className={isLoggedIn() ? 'flex w-full' : 'grid grid-cols-1 md:grid-cols-2'}>
          <div className="flex items-center top-header123 justify-center md:justify-start">
            <a
              onClick={redirectToHome}
              className="block w-[162.75px] h-[15.92px]"
            >
              <img
                id="saLogoId"
                src={theme === 'light' ? '/images/Logo.svg' : '/images/Logo.svg'}
                className=" cursor-pointer w-[162.75px] h-[15.92px] md:block"
              />
            </a>
            {process.env.NEXT_PUBLIC_APP_ENV !== 'production' && <div className="font-bold ml-2">[{process.env.NEXT_PUBLIC_APP_ENV.slice(0, 3)}]</div>}
          </div>
          <div className={`flex items-center w-full ${isLoggedIn() ? ' justify-end ' : ' justify-center md:justify-end mt-4 md:mt-0 '}`}>
            {user && (
              <DropdownDestination logout={logout} nextParam={getNextParam()} />
            )}
          </div>
        </div>
      </Container >
    </div >
  );
};
export default Header;
