import { forwardRef } from 'react';
import {
  Tooltip as NextUITooltip,
  type TooltipProps as NextUITooltipProps,
} from '@nextui-org/tooltip';

export interface TooltipProps extends NextUITooltipProps {
  maxWidth?: string;
}

const container = {
  hover: {
    opacity: 1
  },
  normal: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const Tooltip = forwardRef((props: TooltipProps, ref: any) => {
  if (!props.content) return props.children;

  return (
    <NextUITooltip
      ref={ref}
      {...props}
      showArrow
      initial="normal"
      whileHover="hover"
      animate="hover"
      variants={container}
      delay={0}
      closeDelay={0}
      classNames={{
        base: ['before:bg-black dark:before:bg-black', props.maxWidth ? `${props.maxWidth}` : 'max-w-64'],
        content: ['py-2 px-3 rounded-md text-xs', 'bg-black dark:bg-black text-grey-50'],
      }}
    />
  );
});

export default Tooltip;
