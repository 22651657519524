const ExternalLink = ({ href, className = '', children }) => {
  const handleClick = async (e) => {
    e.preventDefault();
    window.open(href, '_blank');
  };

  return <p className='text-center text-sm mt-8'>
    <a href={href} onClick={handleClick} className={`cursor-pointer ${className}`}>{children}</a>
  </p>;
};

export default ExternalLink;
